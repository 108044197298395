import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Quick_Installation/NavButtons';
import ForumBox from 'components/Quick_Installation/Set_Up_A_Wireless_Connection/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "What is IPv6 and how does SLAAC work?",
  "path": "/Quick_Installation/Internet_Protocol_IPv6/",
  "dateChanged": "2024-05-17",
  "author": "Mike Polinowski",
  "excerpt": "What is IPv6 and how does SLAAC work?",
  "image": "./QI_SearchThumb_WLAN.png",
  "social": "/images/Search/QI_SearchThumb_WLAN.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_QI-Wireless_Connection_white.webp",
  "chapter": "Installation"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='What is IPv6 and how does SLAAC work?' dateChanged='2024-05-17' author='Mike Polinowski' tag='INSTAR IP Camera' description='What is IPv6 and how does SLAAC work?' image='/images/Search/QI_SearchThumb_WLAN.png' twitter='/images/Search/QI_SearchThumb_WLAN.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Schnell_Installation/Internet_Protocol_IPv6/' locationFR='/fr/Quick_Installation/Internet_Protocol_IPv6/' crumbLabel="Wireless" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "internet-protocol-ipv6",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#internet-protocol-ipv6",
        "aria-label": "internet protocol ipv6 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Internet Protocol IPv6`}</h1>
    <h2 {...{
      "id": "what-is-ipv6-and-how-does-slaac-work",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#what-is-ipv6-and-how-does-slaac-work",
        "aria-label": "what is ipv6 and how does slaac work permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What is IPv6 and how does SLAAC work?`}</h2>
    <h3 {...{
      "id": "introduction",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#introduction",
        "aria-label": "introduction permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Introduction`}</h3>
    <p>{`In this article, we would like to explain `}<strong parentName="p">{`IPv6`}</strong>{`, the latest version of the Internet protocol, and how `}<strong parentName="p">{`SLAAC`}</strong>{` (`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Stateless Address Autoconfiguration`}</code>{`) works, which we have integrated into our INSTAR 2K+ cameras. We will also show you how an IPv6 address is structured and how you can call it up in the web browser. Finally, we point out that every INSTAR 2K+ camera is equipped with an integrated DDNS address that supports both IPv4 and IPv6.`}</p>
    <h3 {...{
      "id": "what-is-ipv6",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#what-is-ipv6",
        "aria-label": "what is ipv6 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What is IPv6?`}</h3>
    <p><strong parentName="p">{`IPv6`}</strong>{` (Internet Protocol Version 6) is the successor to `}<strong parentName="p">{`IPv4`}</strong>{`, the previous standard protocol for communication on the Internet. IPv6__ was developed to cope with the increasing number of devices connected to the Internet and offers an almost unlimited number of IP addresses.`}</p>
    <h4 {...{
      "id": "advantages-of-ipv6",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#advantages-of-ipv6",
        "aria-label": "advantages of ipv6 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Advantages of IPv6:`}</h4>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Larger address space`}</strong>{`: IPv6 uses 128-bit addresses, as opposed to the 32-bit addresses of IPv4. This means that IPv6 provides over 340 sextillion addresses.`}</li>
      <li parentName="ul">{`More efficient routing**: IPv6 addresses are hierarchically structured, which makes routing on the Internet more efficient.`}</li>
      <li parentName="ul"><strong parentName="li">{`Improved security`}</strong>{`: IPv6 is designed with built-in security features, such as IPsec, which ensures secure communication over IP networks.`}</li>
    </ul>
    <h3 {...{
      "id": "how-does-slaac-work",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#how-does-slaac-work",
        "aria-label": "how does slaac work permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How does SLAAC work?`}</h3>
    <p>{`SLAAC (Stateless Address Autoconfiguration) is a method by which devices in an IPv6 network can automatically obtain an IPv6 address. It allows devices to assign themselves an IP address without the need for a DHCP (Dynamic Host Configuration Protocol) server.`}</p>
    <h4 {...{
      "id": "how-slaac-works",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#how-slaac-works",
        "aria-label": "how slaac works permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How SLAAC works:`}</h4>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Router Advertisement (RA)`}</strong>{`: An IPv6 router regularly sends Router Advertisement messages to all devices in the network.`}</li>
      <li parentName="ol"><strong parentName="li">{`Prefix detection`}</strong>{`: A device receives an RA message and extracts the prefix from the message.`}</li>
      <li parentName="ol"><strong parentName="li">{`Create address`}</strong>{`: The device creates an IPv6 address by combining the prefix with a self-generated interface identifier.
4 `}<strong parentName="li">{`Double address check`}</strong>{`: The device checks whether the created address is already in use on the network.`}</li>
      <li parentName="ol"><strong parentName="li">{`Address usage`}</strong>{`: If the address is unique, the device uses it as its IPv6 address.`}</li>
    </ol>
    <h3 {...{
      "id": "structure-of-an-ipv6-address",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#structure-of-an-ipv6-address",
        "aria-label": "structure of an ipv6 address permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Structure of an IPv6 address`}</h3>
    <p>{`An IPv6 address consists of eight groups of four hexadecimal digits each, separated by colons. Each group represents 16 bits of the address.`}</p>
    <h4 {...{
      "id": "example-of-an-ipv6-address",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#example-of-an-ipv6-address",
        "aria-label": "example of an ipv6 address permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example of an IPv6 address:`}</h4>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`2001:0db8:85a3:0000:0000:8a2e:0370:7334`}</code></li>
    </ul>
    <h4 {...{
      "id": "abbreviation-of-an-ipv6-address",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#abbreviation-of-an-ipv6-address",
        "aria-label": "abbreviation of an ipv6 address permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Abbreviation of an IPv6 address:`}</h4>
    <p>{`Several consecutive groups of zeros can be replaced by “::”:`}</p>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`2001:0db8:85a3::8a2e:0370:7334`}</code></li>
    </ul>
    <h3 {...{
      "id": "calling-up-an-ipv6-address-in-the-web-browser",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#calling-up-an-ipv6-address-in-the-web-browser",
        "aria-label": "calling up an ipv6 address in the web browser permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Calling up an IPv6 address in the web browser`}</h3>
    <p>{`To call up an IPv6 address in the web browser, you must enclose the address in square brackets. You can use both `}<strong parentName="p">{`http`}</strong>{` and `}<strong parentName="p">{`https`}</strong>{`, although we recommend enabling the `}<strong parentName="p">{`https`}</strong>{` port to ensure the security of your connection.`}</p>
    <h4 {...{
      "id": "example",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#example",
        "aria-label": "example permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example:`}</h4>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`http://[2001:0db8:85a3:0000:0000:8a2e:0370:7334]:80`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`https://[2001:0db8:85a3:0000:0000:8a2e:0370:7334]:443`}</code></li>
    </ul>
    <h3 {...{
      "id": "instar-2k-cameras-and-ddns",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#instar-2k-cameras-and-ddns",
        "aria-label": "instar 2k cameras and ddns permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`INSTAR 2K+ cameras and DDNS`}</h3>
    <p>{`Every INSTAR 2K+ camera comes with an integrated DDNS (Dynamic Domain Name System) address that supports both IPv4 and IPv6 addresses. This allows you to easily access your camera, regardless of whether you are using an IPv4 or IPv6 connection.`}</p>
    <h4 {...{
      "id": "example-of-a-ddns-address",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#example-of-a-ddns-address",
        "aria-label": "example of a ddns address permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Example of a DDNS address:`}</h4>
    <ul>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`http://your-camera.ddns3-instar.de:80`}</code></li>
      <li parentName="ul"><code parentName="li" {...{
          "className": "language-text"
        }}>{`https://your-camera.ddns3-instar.de:443`}</code></li>
    </ul>
    <h3 {...{
      "id": "important-notes-on-ipv6-port-activation",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#important-notes-on-ipv6-port-activation",
        "aria-label": "important notes on ipv6 port activation permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Important notes on IPv6 port activation`}</h3>
    <p>{`Please note that when setting up an IPv6 port activation, the internal and external ports must be the same. We recommend enabling the `}<strong parentName="p">{`HTTPS`}</strong>{` port (usually port `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`443`}</code>{`) to ensure the security of your connection. Make sure that you enable this port correctly in your router.`}</p>
    <h3 {...{
      "id": "summary",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#summary",
        "aria-label": "summary permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Summary`}</h3>
    <p>{`IPv6 is the latest version of the Internet Protocol, which offers numerous advantages, including a larger address space and improved security. With SLAAC, devices can automatically receive IPv6 addresses, which simplifies network configuration. IPv6 addresses are hierarchical and can be accessed in the web browser by entering the address in square brackets. Thanks to the integrated DDNS addresses in our INSTAR 2K+ cameras, you can easily access your cameras via IPv4 and IPv6.`}</p>
    <p>{`We hope that this article has helped you to better understand IPv6 and its benefits. If you have any further questions, please do not hesitate to contact our customer service.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      